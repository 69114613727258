<template>
  <v-autocomplete
    :items="data"
    item-text="name"
    :label="$t('addAuthorization.searchHospitals')"
    :rules="[...fieldRules]"
    hide-no-data
    menu-props="auto"
    @change="selectProvider($event)"
    outlined
    dense
    ref="selectSponsorProviders"
    hide-details="auto"
    autocomplete="nope"
    return-object>
    <template slot="append">
      <v-icon
        class="black--text">mdi-magnify</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { camelizeKeys } from 'humps'
import { inputRules } from "@/utils";
import { createNamespacedHelpers } from 'vuex'
import ProvidersApiService from '@/services/v2/providers-api-v2.service';

const authorizationModule = createNamespacedHelpers('authorizationV2');
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'SponsorProvidersDropDown',
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    sponsorSlug: {
      type: String
    }
  },
  data() {
    return {
      data: null,
      rules: inputRules,
    }
  },
  methods: {
    validate() {
      return this.$refs.selectSponsorProviders.validate(true);
    },
    async fetchData() {
      this.data = await this.getProviders();
    },
    selectProvider(event) {
      if (event.grouped) {
        const provider = {
          providerSlug: event.slug,
          hospitalName: event.name,
          hospitalAddress: event.physicalAddress
        }

        const providerJson = JSON.stringify(provider);

        this.$emit('input', providerJson);
      } else {
        this.$emit('input', event.slug);
      }
    },

    async getProviders(){
      try {
        const sponsorSlug = this.tokenExtensions.sponsorSlug
        const response = await ProvidersApiService.getSponsorProviders(sponsorSlug);
        const result = await response;
        const { data } = result;

        if(data && data.length > 0) {
          return camelizeKeys(data);
        }
        
        return '';

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['inputs', 'authorizationForms']),
    ...authModule.mapGetters(['tokenExtensions']),

    fieldRules() {
      const fieldRulesArray = [];

      if (this.field.required) {
        fieldRulesArray.push(this.rules.required)
      }

      return fieldRulesArray;
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>
